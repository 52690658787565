<template>
  <div class="list-a" v-loading="loading">
    <div v-if="noData" class="no-data">
      <img :src="img" alt="">
    </div>
    <ul>
      <li v-for="(item, index) of list" :key="index"  class="news-li news-li-border">
        <div class="news-left" style="width: 550px;" @click="itemClick(item)">{{item.title}}</div>
        <div class="news-time">{{item.publishTime}}11</div>
      </li>
    </ul>
    <Pagination :total="total" :size="size" :page="current" @PaginationFun="PaginationFun"></Pagination>
  </div>
</template>

<script>
import { getList } from '@/api/web'
import Pagination from '@/components/pagination/Pagination.vue'
export default {
  components: {
    Pagination
  },
  name: 'ListA',
  data () {
    return {
      img: require('../../../assets/images/nodata.png'),
      loading: false,
      list: [],
      noData: false,
      total: 0,
      current: 1,
      size: 10
    }
  },
  watch: {
    '$route.fullPath': function (n, o) {
      this.current = 1
      this.initialData()
    }
  },
  created () {
    if (this.$route.query.id) {
      this.initialData()
    }
  },
  mounted () {},
  methods: {
    itemClick (item) {
      if (item.url) {
        window.open(item.url, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/detail',
          query: { id: this.$route.query.id, articleId: item.id }
        })
        window.open(href, '_blank')
      }
    },
    initialData () {
      this.loading = true
      const data = {
        cmsType: this.$route.query.cid,
        pageSize: this.size,
        pageNo: this.current
      }
      getList(data).then(res => {
        this.loading = false
        if (res.code === '000') {
          this.list = res.result.records
          this.total = res.result.total
          this.current = res.result.current
          if (res.result.total === 0) {
            this.noData = true
          } else {
            this.noData = false
          }
        }
      })
    },
    PaginationFun (e) {
      this.current = e
      this.initialData()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../assets/style/list.css";
.list-a{
  padding: 20px 25px 80px 25px;
  min-height: 520px;
  position: relative;
}
</style>
